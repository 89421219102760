import axios from "axios";

export default class PresentacionesService {
  getAll(){
    return axios.get(process.env.VUE_APP_API_PUBLIC + "presentaciones/all")
  }
  getById(id){
    return axios.get(process.env.VUE_APP_API_PUBLIC + "presentaciones/"+id)
  }
  createPresentacion(data){
    return axios.post(process.env.VUE_APP_API_PUBLIC + "presentaciones",data)
  }
  deletePresentacion(id){
    const data={
      id
    }
    return axios.delete(process.env.VUE_APP_API_PUBLIC + "presentaciones",{data})
  }
  editPresentacion(data){
    return axios.post(process.env.VUE_APP_API_PUBLIC + "presentaciones/edit",data)
  }
}
